var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-fp-comment-table"},[_c('filter-panel',{on:{"clear":_vm.clearFilter,"filter":_vm.filterData},model:{value:(_vm.isFilterActive),callback:function ($$v) {_vm.isFilterActive=$$v},expression:"isFilterActive"}},[_c('div',{staticClass:"row p-2"},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Agent Name","name":"filterAgentName","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData()}},model:{value:(_vm.filter['agencyUser:name']),callback:function ($$v) {_vm.$set(_vm.filter, 'agencyUser:name', $$v)},expression:"filter['agencyUser:name']"}}),_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Floor Plan Name","name":"filterprojectUnitFloorPlan","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData()}},model:{value:(_vm.filter['projectUnitFloorPlan:name']),callback:function ($$v) {_vm.$set(_vm.filter, 'projectUnitFloorPlan:name', $$v)},expression:"filter['projectUnitFloorPlan:name']"}})],1)]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.commentTableColumns,"rows":_vm.commentTableData,"totalRows":_vm.commentTablePagination.total,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: _vm.perPageOptions,
        dropdownAllowAll: false
      },"sort-options":{
        enabled: false
      }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.openCommentDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(
            props.column.field == 'actions' && _vm.status == 'Pending Approval'
          )?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('button',{staticClass:"btn success",on:{"click":function($event){return _vm.approve(props.row.id)}}},[_c('i',{staticClass:"fas fa-check"})]),_c('button',{staticClass:"btn danger bordered ml-1",on:{"click":function($event){return _vm.reject(props.row.id)}}},[_c('i',{staticClass:"fas fa-times"})])]):_vm._e(),(props.column.field == 'agent')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"d-flex align-items-center"},[(props.row.agencyUser.avatar[0])?_c('img',{staticClass:"avatar",attrs:{"src":_vm.$getFileURL(props.row.agencyUser.avatar[0]),"alt":props.row.agencyUser.name,"loading":"lazy"},on:{"error":_vm.setDefaultAvatar}}):_vm._e(),_c('p',{staticClass:"d-inline-block ml-1"},[_vm._v(" "+_vm._s(props.row.agencyUser.name)+" ")])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no data"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{staticClass:"btn toggle",class:{ active: _vm.isFilterActive },on:{"click":_vm.filterToggle}},[_c('i',{staticClass:"fas fa-filter"})])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }